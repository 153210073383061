export const isTokenValidForNext3Hours = (token: string): boolean => {
  try {
    // Lấy phần payload của token
    const base64Url = token.split('.')[1];

    // Chuyển đổi từ Base64URL sang Base64
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    // Giải mã Base64 thành JSON
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    // Parse JSON để lấy exp và expired (thời gian hết hạn)
    const { exp, expired } = JSON.parse(jsonPayload);

    // Xác định thời gian hết hạn, ưu tiên trường expired nếu có
    const expirationTime = expired ?? exp;

    if (!expirationTime) {
      throw new Error('Expiration time not found in token');
    }

    // Lấy thời gian hiện tại và thời gian 3 giờ tới
    const currentTime = Date.now();
    const threeHoursFromNow = currentTime + 3 * 60 * 60 * 1000;

    // Kiểm tra token có còn hợp lệ ít nhất 3 giờ không
    return expirationTime * 1000 > threeHoursFromNow;
  } catch (error) {
    console.error('Invalid JWT token:', error);
    return false; // Trả về false nếu có lỗi xảy ra (token không hợp lệ)
  }
};
